import React from 'react';
import './SideMenu.css';
import facebookImg from '../../media/images/icons/facebook.svg'
import linkedinImg from '../../media/images/icons/linkedin.svg'
import {Link} from 'react-router-dom'
import logoImg from '../../media/images/logo_op1_white.svg'
import equisImg from '../../media/images/icons/equis.svg'
import Texts from './text.json'



function SideMenu(props){

  const language = props.language

  let menuClasses = 'side-menu primary-bg side-menu-font'
  if (props.show){
    menuClasses ='side-menu primary-bg side-menu-font open'
  }
  return(
    <nav className={menuClasses}>
      <ul>
        <li>
          <Link to={'/'+language+'/'} style={{ textDecoration: 'none'}}>
          <img src={logoImg} className="logo"></img>
          </Link>
        </li>
        <li>
          <Link to={'/'+language+'/'} style={{ textDecoration: 'none'}}>
            <div className="white-tx">{Texts.[language].home}</div>
          </Link>
        </li>
        <li>
          <Link to={'/'+language+'/about'} style={{ textDecoration: 'none'}}>
            <div className="white-tx">{Texts.[language].aboutus}</div>
          </Link>
        </li>
        <li>
          <Link to={'/'+language+'/products'} style={{ textDecoration: 'none'}}>
            <div className="white-tx">{Texts.[language].products}</div>
          </Link>
        </li>
        <li>
          <Link to={'/'+language+'/infrastructure'} style={{ textDecoration: 'none'}}>
            <div className="white-tx">{Texts.[language].infrastructure}</div>
          </Link>
        </li>
        <li>
          <Link to={'/'+language+'/contact'} style={{ textDecoration: 'none'}}>
            <div className="white-tx">{Texts.[language].contact}</div>
          </Link>
        </li>
        <li>
          <a target="_blank" href="https://facebook.com/Macroalgas-Trading-100517108805411">
            <img className="icon-size" src={facebookImg}></img>
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/71766479/admin/">
            <img className="icon-size" src={linkedinImg}></img>
          </a>
        </li>
      </ul>
      <button class="out-button" onClick={props.sideMenuHanlder}>
        <img height="20px" src={equisImg}></img>
      </button>
    </nav>
  )
}

export default SideMenu
