import React from 'react'
import './DetailsBox.css'

function DetailsBox(props){

  return(
    <div className={"col-9 col-md-3 d-flex align-items-center justify-content-center mx-2 my-3 details-font-color " + props.background}>
      <div className="row px-3 py-5">
        <div className="col-12 pb-3">
          <img className="details-icon" src={require('../../media/images/icons/'+props.img).default}></img>
        </div>
        <div className="col-12 pb-3">
          <div className="details-title-font">{props.title}</div>
        </div>
        <div className="col-12">
          <div className="details-message-font">{props.message}</div>
        </div>
      </div>
    </div>
  )
}

export default DetailsBox
