import React from 'react'
import './Footer.css'
import logoImg from '../../media/images/logo_op1_white.svg'
import Texts from './text.json'


function Footer(props){

  const language = props.language

  return(
    <footer>
      <div className="container-fluid primary-bg">
        <div className="row justify-content-center py-3">
          <a href="#">
            <img src={logoImg} className="logo_footer"></img>
          </a>
        </div>
        <div className="row justify-content-center footer-font pb-3">
          <div className="col-12 text-center">
            <div className="white-tx">
              {Texts.[language].address}
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="white-tx">
              © 2021 Macroalgas Trading S.A.C.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
