import React from 'react';
import Navbar from '../../components/Navbar/Navbar'
import SideMenu from '../../components/SideMenu/SideMenu'

class Header extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      language : props.language
    }
  }

  state = {
    sideMenuOpen: false
  };

  sideMenuClickHandler = () => {
    this.setState((prevState)=>{
      return {
        sideMenuOpen: !prevState.sideMenuOpen
      }
    })
  }

  render(){
    return(
      <div className="position-relative">
        <Navbar sideMenuHanlder={this.sideMenuClickHandler} language={this.state.language}/>
        <SideMenu show={this.state.sideMenuOpen} sideMenuHanlder={this.sideMenuClickHandler} language={this.state.language}/>
      </div>
    )
  }
}


export default Header
