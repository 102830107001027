import React from 'react'
import './Button.css'
import {Link} from 'react-router-dom'


function Button(props){
  return(
    <Link to={props.location} style={{ textDecoration: 'none'}}>
      <button className="primary-light-bg btn-font btn-macroalgas white-tx">
        {props.message}
      </button>
    </Link>
  )
}

export default Button
