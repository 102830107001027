import React from 'react'
import './FooterKoders.css'
import Texts from './text.json'

function FooterKoders(props){

  const language = props.language

  return(
    <div className="container-fluid primary-dark-bg">
      <div className="row py-1 footer-font white-tx justify-content-center">
        <div>{Texts.[language].developed}<u><a className="white-tx">koders.pe</a></u></div>
      </div>
    </div>
  )
}

export default FooterKoders
