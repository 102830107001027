import React from 'react';
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import FooterKoders from '../../components/FooterKoders/FooterKoders'
import whatsappImg from '../../media/images/icons/whatsapp.svg'
import emailjs from 'emailjs-com'
import './Contact.css'
import Texts from './text.json'


class Contact extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      margin: 0,
      window_height: 0,
      thanks: "d-none",
      button: "d-block",
      language : props.language
    }
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentDidMount(){
    this.setState({
      margin: document.getElementById("navbar").offsetHeight,
      window_height: window.innerHeight
    })
  }

  sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_wbl8edc', e.target, 'user_iQth4neNw048rmNytmukX')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      this.setState({
        thanks: "d-block",
        button: "d-none"
      })
  }


  render(){
    return(
      <div>
        <Header language={this.state.language}/>
        <div className="container-fluid" style={{paddingTop: this.state.margin}}>
          <div className="row">
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center contact-background"
              style={{
              backgroundImage: `linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(${require('../../media/images/contacto.jpg').default})`,
              "height":this.state.window_height - this.state.margin
              }}>
              <div className="row justify-content-center title-font mb-3">
                <span className="black-tx">{Texts.[this.state.language].title_title} <span className="primary-tx">{Texts.[this.state.language].title_title_highlight}</span></span>
              </div>
              <div className="row justify-content-center">
                <div className="col-9 text-center">
                  <form className="contact-form" action="#" onSubmit={this.sendEmail}>
                    <input type="text" id="name" name="name" placeholder={Texts.[this.state.language].name_placeholder} className="my-2 secondary-light-bg p-1 border-contact contact-font" required></input>
                    <input type="email" id="email" name="email" placeholder={Texts.[this.state.language].email_placeholder} className="my-2 secondary-light-bg p-1 border-contact contact-font" required></input>
                    <input type="text" id="company" name="company" placeholder={Texts.[this.state.language].company_placeholder} className="my-2 secondary-light-bg p-1 border-contact contact-font" required></input>
                    <textarea  type="text" id="message" rows="5" name="message" placeholder={Texts.[this.state.language].message_placeholder} className="my-2 secondary-light-bg p-1 border-contact contact-font" required></textarea>
                    <div className={"paragraph-font primary-darker-tx " + this.state.thanks}>{Texts.[this.state.language].thanks_message}</div>
                    <input type="submit" value={Texts.[this.state.language].send_button} className={"primary-light-bg btn-font btn-macroalgas white-tx mt-4 " + this.state.button}></input>
                  </form>
                </div>
              </div>
              <div className="row justify-content-center mt-3 mt-md-5">
                <div className="footer-font primary-darker-tx">comercial@macroalgasperu.com</div>
              </div>
              <div className="row justify-content-center">
                <div className="footer-font primary-darker-tx"><img src={whatsappImg} height="15px"></img> {Texts.[this.state.language].phone}</div>
              </div>
            </div>
            <div className="col-12 col-lg-6 px-0">
              <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15261.348984980452!2d-72.09869444839696!3d-17.007120012526727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDAwJzIyLjkiUyA3MsKwMDUnNDIuMCJX!5e0!3m2!1sen!2spe!4v1615864435745!5m2!1sen!2spe" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
        <Footer language={this.state.language}/>
        <FooterKoders language={this.state.language}/>
      </div>

    )
  }
}
export default Contact
