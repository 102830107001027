import React from 'react';
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import DetailsBox from '../../components/DetailsBox/DetailsBox'
import pescadorImg from '../../media/images/pescador.jpg'
import exportImg from '../../media/images/export.svg'
import SectionText from '../../components/SectionText/SectionText'
import Footer from '../../components/Footer/Footer'
import FooterKoders from '../../components/FooterKoders/FooterKoders'
import Header from '../../components/Header/Header'
import Texts from './text.json'


function Landing(props){

  const language = props.language

  return(
    <div>
      <Header language={language}/>
      <Banner
        text_highlight={Texts.[language].banner_text_highlight}
        text={Texts.[language].banner_text}
        location={"/" + language + "/products"}
        button_message={Texts.[language].banner_button}
      />
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row justify-content-center text-center title-font">
            <div className="black-tx my-5">{Texts.[language].details_text}<span className="primary-lighter-tx">{Texts.[language].details_text_highlight}</span></div>
          </div>
          <div className="row justify-content-center">
            <DetailsBox
              background="white-bg"
              title={Texts.[language].box_title_1}
              message={Texts.[language].box_description_1}
              img="pescador.svg"
            />
            <DetailsBox
              background="primary-light-bg"
              title={Texts.[language].box_title_2}
              message={Texts.[language].box_description_2}
              img="proceso.svg"
            />
            <DetailsBox
              background="white-bg"
              title={Texts.[language].box_title_3}
              message={Texts.[language].box_description_3}
              img="barco.svg"
            />
          </div>
          <div className="row justify-content-center pt-4 pb-5">
            <Button message={Texts.[language].details_button} location={"/" + language + "/infrastructure"}/>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 px-0 text-center">
              <img src={pescadorImg} className="img-fluid"></img>
            </div>
            <SectionText
              message={[Texts.[language].sustainability_paragraph_1]}
              title={Texts.[language].sustainability_title}
              title_highlight={Texts.[language].sustainability_title_highlight}
              button_message={Texts.[language].sustainability_button}
              location={"/" + language + "/about"}
              display='d-block'
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <SectionText
              message={[Texts.[language].clients_paragraph_1]}
              title={Texts.[language].clients_title}
              title_highlight={Texts.[language].clients_title_highlight}
              button_message={Texts.[language].clients_button}
              location={"/" + language + "/contact"}
              display='d-block'
            />
            <div className="col-12 col-md-6 p-4 p-sm-5 my-0 my-md-5 order-1">
              <img src={exportImg} className="img-fluid"></img>
            </div>
          </div>
        </div>
      </section>
      <Footer language={language}/>
      <FooterKoders language={language}/>
    </div>
  )
}

export default Landing
