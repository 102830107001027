import React from 'react'
import './ProductBanner.css'
import Button from '../Button/Button'

function ProductBanner(props){
  return(
    <div className="container-fluid py-5 mb-3 banner-bg"
      style={{
      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${require('../../media/images/'+props.img).default})`
      }}>
      <div className="row justify-content-center py-3 py-md-5">
        <div className="col-10">
          <div className="row mb-1">
            <div className="white-tx title-font">{props.algae}</div>
          </div>
          <div className="row mb-4">
            <div className="white-tx paragraph-font">{props.description}</div>
          </div>
          <div className="row">
            <Button message={props.btn_message} location={props.location}/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProductBanner
