import React from 'react';
import './Upbar.css'


function Upbar(props){
  return(
    <div className="container-fluid">
      <div className="row primary-bg justify-content-end primary-font">
        <a href="/en" className="white-tx mx-1">EN</a>
        <span className="white-tx">l</span>
        <a href="/es" className="white-tx mx-1">ES</a>
      </div>
    </div>
  )
}

export default Upbar
