import React from 'react'
import './SectionText.css'
import Button from '../Button/Button'

function SectionText(props){

  return(
    <div className="col order-2 order-md-1 d-flex align-items-center justify-content-center py-5 py-md-3">
      <div className="row w-100 justify-content-center">
        <div className="col-11 col-lg-10">
          <div className="black-tx mb-3 title-font">{props.title}<span className="primary-lighter-tx">{props.title_highlight}</span></div>
          {props.message.map((msg)=>{
            return(
              <div><div className="paragraph-font mb-2">{msg}</div></div>
            )
          })}
          <div className={"mt-4 "+props.display}>
            <Button message={props.button_message} location={props.location}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionText
