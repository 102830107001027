import React from 'react';
import './Navbar.css'
import Upbar from '../../components/Upbar/Upbar'
import logoImg from '../../media/images/logo_op2.svg'
import facebookImg from '../../media/images/icons/facebook.svg'
import instagramImg from '../../media/images/icons/instagram.svg'
import linkedinImg from '../../media/images/icons/linkedin.svg'
import sandwichImg from '../../media/images/icons/sandwich.svg'
import {Link} from 'react-router-dom'
import Texts from './text.json'


function Navbar(props){

  const language = props.language

  return(
    <div className="position-fixed w-100 z-index-1" id="navbar">
      <Upbar language = {language}/>
      <nav className = "navbar navbar-expand-lg nav-background-transparent">
        <div className="navbar-brand z-index-2">
          <Link to={'/'+language} style={{ textDecoration: 'none'}}>
            <img src={logoImg} height="40" alt=""></img>
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarData">
          <ul className="navbar-nav w-100 justify-content-center absolute-center navbar-font">
            <li className="nav-item mx-2">
              <Link to={'/'+language+'/'} style={{ textDecoration: 'none'}}>
                <div className="nav-link nav-text-color">{Texts.[language].home}</div>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link to={'/'+language+'/about'} style={{ textDecoration: 'none'}}>
                <div className="nav-link nav-text-color">{Texts.[language].aboutus}</div>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link to={'/'+language+'/products'} style={{ textDecoration: 'none'}}>
                <div className="nav-link nav-text-color">{Texts.[language].products}</div>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link to={'/'+language+'/infrastructure'} style={{ textDecoration: 'none'}}>
                <div className="nav-link nav-text-color">{Texts.[language].infrastructure}</div>
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link to={'/'+language+'/contact'} style={{ textDecoration: 'none'}}>
                <div className="nav-link nav-text-color">{Texts.[language].contact}</div>
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item z-index-2">
            <a target="_blank" href="https://facebook.com/Macroalgas-Trading-100517108805411">
              <div className="nav-link">
                <img className="icons-size" src={facebookImg}></img>
              </div>
            </a>
            </li>
            <li className="nav-item z-index-2">
            <a target="_blank" href="https://www.linkedin.com/company/71766479/admin/">
              <div className="nav-link">
                <img className="icons-size" src={linkedinImg}></img>
              </div>
            </a>
            </li>
          </ul>
        </div>
        <button className="navbar-toggler px-0" onClick={props.sideMenuHanlder}>
          <img className="icons-size" src={sandwichImg}></img>
        </button>
      </nav>
    </div>

  )
}

export default Navbar
