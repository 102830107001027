import React from 'react';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Title from '../../components/Title/Title'
import SectionText from '../../components/SectionText/SectionText'
import Subtitle from '../../components/Subtitle/Subtitle'
import FooterKoders from '../../components/FooterKoders/FooterKoders'
import ProductBanner from '../../components/ProductBanner/ProductBanner'
import calidadImg from '../../media/images/calidad.jpg'
import Texts from './text.json'




function Products(props){

  const language = props.language

  return(
    <div>
      <Header language={language}/>
      <Title
        title={Texts.[language].title_title}
        img="producto.jpg"
      />
      <div className="mb-3">
        <Subtitle
          img="bolsas.jpg"
          message={Texts.[language].subtitle_paragraph}
        />
      </div>
      <ProductBanner
        img="materia_prima1.jpg"
        algae={Texts.[language].algae_title_1}
        description={Texts.[language].algae_description_1}
        location={"/"+language+"/products/macrocystis_integrifolia"}
        btn_message={Texts.[language].btn_message}
        />
      <ProductBanner
        img="materia_prima2.jpg"
        algae={Texts.[language].algae_title_2}
        description={Texts.[language].algae_description_2}
        location={"/"+language+"/products/lessonia_nigrescens"}
        btn_message={Texts.[language].btn_message}
        />
      <ProductBanner
        img="materia_prima3.jpg"
        algae={Texts.[language].algae_title_3}
        description={Texts.[language].algae_description_3}
        location={"/"+language+"/products/lessonia_trabeculata"}
        btn_message={Texts.[language].btn_message}
        />
      <Footer language={language}/>
      <FooterKoders language={language}/>
    </div>
  )
}

export default Products
