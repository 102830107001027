import React from 'react';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Title from '../../components/Title/Title'
import SectionText from '../../components/SectionText/SectionText'
import Subtitle from '../../components/Subtitle/Subtitle'
import FooterKoders from '../../components/FooterKoders/FooterKoders'
import infraestructuraImg from '../../media/images/infraestructura.jpg'
import procesamientoImg from '../../media/images/procesamiento.jpg'
import suelo1Img from '../../media/images/suelo1.jpg'
import suelo2Img from '../../media/images/suelo2.jpg'
import containerImg from '../../media/images/container.jpg'
import calidadImg from '../../media/images/calidad.jpg'
import Texts from './text.json'



function Infrastructure(props){

  const language = props.language

  return(
    <div>
      <Header language={language}/>
      <Title
        title={Texts.[language].title_title}
        img="infraestructura.jpg"
      />
      <Subtitle
        img="area.jpg"
        title={Texts.[language].subtitle_title}
        title_highlight={Texts.[language].subtitle_title_highlight}
        message={Texts.[language].subtitle_paragraph}
      />
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <div className="col-12 col-md-6 px-0">
              <img src={suelo1Img} className="img-fluid"></img>
            </div>
            <SectionText
              message={Texts.[language].predrying_paragraph}
              title={Texts.[language].predrying_title}
              title_highlight={Texts.[language].predrying_title_highlight}
              display='d-none'
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <SectionText
              message={Texts.[language].lines_paragraph}
              title={Texts.[language].lines_title}
              title_highlight={Texts.[language].lines_title_highlight}
              display='d-none'
            />
            <div className="col-12 col-md-6 px-0 order-1">
              <img src={procesamientoImg} className="img-fluid"></img>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <div className="col-12 col-md-6 px-0">
              <img src={suelo2Img} className="img-fluid"></img>
            </div>
            <SectionText
              title={Texts.[language].drying_title}
              title_highlight={Texts.[language].drying_title_highlight}
              message={Texts.[language].drying_paragraph}
              display='d-none'
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <SectionText
              title={Texts.[language].quality_title}
              title_highlight={Texts.[language].quality_title_highlight}
              message={Texts.[language].quality_paragraph}
              display='d-none'
            />
            <div className="col-12 col-md-6 px-0 order-1">
              <img src={calidadImg} className="img-fluid"></img>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <div className="col-12 col-md-6 px-0">
              <img src={containerImg} className="img-fluid"></img>
            </div>
            <SectionText
              title={Texts.[language].shipping_title}
              title_highlight={Texts.[language].shipping_title_highlight}
              message={Texts.[language].shipping_paragraph}
              display='d-none'
            />
          </div>
        </div>
      </section>
      <Footer language={language}/>
      <FooterKoders language={language}/>
    </div>
  )
}

export default Infrastructure
