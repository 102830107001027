import React from 'react';
import './TechnicalSpecifications.css'

function TechnicalSpecifications(props){

  return(
    <section>
      <div className="container-fluid my-3 py-5 specifications-background"
        style={{
        backgroundImage: `linear-gradient(rgba(113,157,6,0.9), rgba(113,157,6,0.9)), url(${require('../../media/images/'+'especificaciones.jpg').default})`
        }}
        >
        <div className="row justify-content-center py-3">
          <div className="col-9">
            <div className="row mb-5">
              <div className="white-tx title-font">
                {props.technical_specifications_title}
              </div>
            </div>
            <div className="row mb-4">
              <table className="paragraph-font white-tx w-100">
                <tr>
                  <td>{props.product_label}</td>
                  <td>{props.product}</td>
                </tr>
                <tr>
                  <td>{props.variety_label}</td>
                  <td>{props.variety}</td>
                </tr>
                <tr>
                  <td>{props.style_label}</td>
                  <td>{props.style}</td>
                </tr>
                <tr>
                  <td>{props.processing_label}</td>
                  <td>{props.processing}</td>
                </tr>
                <tr>
                  <td>{props.drying_label}</td>
                  <td>{props.drying}</td>
                </tr>
                <tr>
                  <td>{props.moisture_label}</td>
                  <td>{props.moisture}</td>
                </tr>
                <tr>
                  <td>{props.impurities_label}</td>
                  <td>{props.impurities}</td>
                </tr>
                <tr>
                  <td>{props.size_label}</td>
                  <td>{props.size}</td>
                </tr>
                <tr>
                  <td>{props.place_label}</td>
                  <td>{props.place}</td>
                </tr>
                <tr>
                  <td>{props.packing_label}</td>
                  <td>{props.packing}</td>
                </tr>
                <tr>
                  <td>{props.certificates_label}</td>
                  <td>{props.certificates}</td>
                </tr>
              </table>
            </div>
            <div className="row">
              <div className="paragraph-font white-tx">
                {props.comments}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TechnicalSpecifications
