import React from 'react'
import Landing from '../pages/Landing/Landing'
import AboutUs from '../pages/AboutUs/AboutUs'
import Infrastructure from '../pages/Infrastructure/Infrastructure'
import Products from '../pages/Products/Products'
import Algae from '../pages/Algae/Algae'
import Contact from '../pages/Contact/Contact'
import Oops from '../pages/Oops/Oops'
import ScrollToTop from './ScrollToTop/ScrollToTop'




import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

function App(){
  return (
    <Router>
    <ScrollToTop/>
      <Switch>
        <Route exact path="/" render={()=><Landing language="es"/>} />
        <Route exact path="/en" render={()=><Landing language="en"/>} />
        <Route exact path="/es" render={()=><Landing language="es"/>} />
        <Route exact path="/en/about" render={()=><AboutUs language="en"/>} />
        <Route exact path="/es/about" render={()=><AboutUs language="es"/>} />
        <Route exact path="/en/contact" render={()=><Contact language="en"/>} />
        <Route exact path="/es/contact" render={()=><Contact language="es"/>} />
        <Route exact path="/en/infrastructure" render={()=><Infrastructure language="en"/>} />
        <Route exact path="/es/infrastructure" render={()=><Infrastructure language="es"/>} />
        <Route exact path="/en/products" render={()=><Products language="en"/>} />
        <Route exact path="/es/products" render={()=><Products language="es"/>} />
        <Route exact path="/en/products/macrocystis_integrifolia" render={()=><Algae
          algae="macrocystis_integrifolia"
          language="en"
          data={{
            "title_img": "alga1_lut.jpg",
            "raw_img": "materia_prima1.jpg",
            "algae_banners":[
              {
                "algae_img":"materia_prima2.jpg",
              },
              {
                "algae_img":"materia_prima3.jpg",
              }
            ]
          }}/>} />
        <Route exact path="/es/products/macrocystis_integrifolia" render={()=><Algae
            algae="macrocystis_integrifolia"
            language="es"
            data={{
              "title_img": "alga1_lut.jpg",
              "raw_img": "materia_prima1.jpg",
              "algae_banners":[
                {
                  "algae_img":"materia_prima2.jpg",
                },
                {
                  "algae_img":"materia_prima3.jpg",
                }
              ]
            }}/>} />
        <Route exact path="/en/products/lessonia_nigrescens" render={()=><Algae
          algae="lessonia_nigrescens"
          language="en"
          data={{
            "title_img": "alga2_lut.jpg",
            "raw_img": "materia_prima2.jpg",
            "algae_banners":[
              {
                "algae_img":"materia_prima1.jpg",
              },
              {
                "algae_img":"materia_prima3.jpg",
              }
            ]
          }}/>} />
        <Route exact path="/es/products/lessonia_nigrescens" render={()=><Algae
            algae="lessonia_nigrescens"
            language="es"
            data={{
              "title_img": "alga2_lut.jpg",
              "raw_img": "materia_prima2.jpg",
              "algae_banners":[
                {
                  "algae_img":"materia_prima1.jpg",
                },
                {
                  "algae_img":"materia_prima3.jpg",
                }
              ]
            }}/>} />
        <Route exact path="/en/products/lessonia_trabeculata" render={()=><Algae
          algae="lessonia_trabeculata"
          language="en"
          data={{
            "title_img": "alga3_lut.jpg",
            "raw_img": "materia_prima3.jpg",
            "algae_banners":[
              {
                "algae_img":"materia_prima1.jpg"
              },
              {
                "algae_img":"materia_prima2.jpg"
              }
            ]
          }}/>} />
        <Route exact path="/es/products/lessonia_trabeculata" render={()=><Algae
          algae="lessonia_trabeculata"
          language="es"
          data={{
            "title_img": "alga3_lut.jpg",
            "raw_img": "materia_prima3.jpg",
            "algae_banners":[
              {
                "algae_img":"materia_prima1.jpg"
              },
              {
                "algae_img":"materia_prima2.jpg"
              }
            ]
          }}/>} />
        <Route component={Oops} />
      </Switch>
    </Router>
  )
}

export default App
