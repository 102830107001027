import React from 'react';
import './Title.css'

class Title extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      margin: 0
    }
  }

  componentDidMount(){
    this.setState({
      margin: document.getElementById("navbar").offsetHeight
    })
  }

  render(){
    return(
      <div style={{paddingTop: this.state.margin}}>
        <div className="title-background container-fluid py-1 py-md-3 py-lg-5"
          style={{
          backgroundImage: `url(${require('../../media/images/'+this.props.img).default})`
          }}
        >
          <div className="row justify-content-center py-5">
            <div className="col-11">
              <div className="row text-center justify-content-center py-5 my-5">
                <div className="photo-font white-tx">
                  {this.props.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Title
