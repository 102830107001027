import React from 'react';
import Button from '../../components/Button/Button'
import oopsImg from '../../media/images/icons/404.svg'
import './Oops.css'


class Oops extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      window_height: window.innerHeight
    }
  }

  componentDidMount() {
    window.addEventListener('resize', ()=>{
      this.setState({
        window_height: window.innerHeight
      })
    })
  }

  render(){
    return(
      <div className="oops_container secondary-light-bg" style={{height: this.state.window_height}}>
        <div className="row justify-content-center">
          <div className="col-8 col-lg-4 col-xl-3">
            <div className="row">
              <img className="img-fluid" src={oopsImg}></img>
            </div>
            <div className="row text-center my-3">
              <span className="primary-dark-tx details-title-font">Sorry, but the page you are looking for does not exist!</span>
            </div>
            <div className="row justify-content-center my-5">
              <Button
              location="/en"
              message="GO TO HOME"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default Oops
