import React from 'react';
import './Subtitle.css'

function Subtitle(props){

  return(
    <div className="container-fluid subtitle-background py-5"
      style={{
      backgroundImage: `linear-gradient(rgba(113,157,6,0.81), rgba(113,157,6,0.81)), url(${require('../../media/images/'+props.img).default})`
      }}
    >
      <div className="row justify-content-center py-5">
        <div className="col-10 col-md-5 text-center">
          <div className="title-font row justify-content-center mb-4">
            <span className="white-tx">{props.title} <span className="black-tx">{props.title_highlight}</span></span>
          </div>
          <div className="paragraph-font row justify-content-center">
            {props.message.map((msg)=>{
              return(
                <div><span className="white-tx">{msg}<br/><br/></span></div>
              )
            })}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Subtitle
