import React from 'react';
import './Banner.css'
import Button from '../Button/Button'
import manoImg from '../../media/images/mano.jpg'


function Banner(props){
  return(
    <div className="container-fluid bg-banner"
      style={{
      backgroundImage: `url(${manoImg})`}}
      >
      <div className="row justify-content-center justify-content-lg-start">
        <div className="d-flex vh-100 align-items-center col-11 col-xl-4 offset-0 offset-lg-1">
          <div className="row">
            <div className="col-12">
              <span className="banner-font primary-lighter-tx">{props.text_highlight} </span>
              <span className="banner-font secondary-light-tx">{props.text}</span>
            </div>
            <div className="col-12 pt-2">
              <Button
                location = {props.location}
                message = {props.button_message}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
