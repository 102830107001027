import React from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Title from '../../components/Title/Title'
import SectionText from '../../components/SectionText/SectionText'
import Subtitle from '../../components/Subtitle/Subtitle'
import FooterKoders from '../../components/FooterKoders/FooterKoders'
import quienesImg from '../../media/images/quienes.jpg'
import dondeImg from '../../media/images/donde.jpg'
import Texts from './text.json'



function AboutUs(props){

  const language = props.language

  return(
    <div>
      <Header language={language}/>
      <Title
        title={Texts.[language].title_title}
        img="equipo.jpg"
      />
      <Subtitle
        img="producto.jpg"
        title={Texts.[language].subtitle_title}
        title_highlight={Texts.[language].subtitle_title_highlight}
        message={Texts.[language].subtitle_paragraph}
      />
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <div className="col-12 col-md-6 px-0">
              <img src={quienesImg} className="img-fluid"></img>
            </div>
            <SectionText
              message={Texts.[language].who_paragraph}
              title={Texts.[language].who_title}
              title_highlight={Texts.[language].who_title_highlight}
              display='d-none'
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <SectionText
              message={Texts.[language].where_paragraph}
              title={Texts.[language].where_title}
              title_highlight={Texts.[language].where_title_highlight}
              display='d-none'
            />
            <div className="col-12 col-md-6 px-0 order-1">
              <img src={dondeImg} className="img-fluid"></img>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid white-bg">
          <div className="row justify-content-center title-font">
            <div className="black-tx my-5">{Texts.[language].values_title} <span className="primary-lighter-tx">{Texts.[language].values_title_highlight}</span></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 text-center">
              <div className="row justify-content-center pt-2 pb-5">
                <div className="col-12 col-md-4 col-xl-3 text-center mb-3">
                  <div className="banner-font primary-tx mb-4">1</div>
                  <div className="paragraph-font black-tx mb-3"><b>{Texts.[language].value_info_title_1}</b></div>
                  <div className="details-message-font black-tx">{Texts.[language].value_info_text_1}</div>
                </div>
                <div className="col-12 col-md-4 col-xl-3 text-center mb-3">
                  <div className="banner-font primary-tx mb-4">2</div>
                  <div className="paragraph-font black-tx mb-3"><b>{Texts.[language].value_info_title_2}</b></div>
                  <div className="details-message-font black-tx">{Texts.[language].value_info_text_2}</div>
                </div>
                <div className="col-12 col-md-4 col-xl-3 text-center mb-3">
                  <div className="banner-font primary-tx mb-4">3</div>
                  <div className="paragraph-font black-tx mb-3"><b>{Texts.[language].value_info_title_3}</b></div>
                  <div className="details-message-font black-tx">{Texts.[language].value_info_text_3}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer language={language}/>
      <FooterKoders language={language}/>
    </div>
  )
}

export default AboutUs
