import React from 'react';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Title from '../../components/Title/Title'
import TechnicalSpecifications from '../../components/TechnicalSpecifications/TechnicalSpecifications'
import SectionText from '../../components/SectionText/SectionText'
import FooterKoders from '../../components/FooterKoders/FooterKoders'
import ProductBanner from '../../components/ProductBanner/ProductBanner'
import Texts from './text.json'



function Products(props){

  const language = props.language
  const algae = props.algae

  return(
    <div>
      <Header language={language}/>
      <Title
        title={Texts.[language].title_title}
        img={props.data.title_img}
      />
      <section className="my-3">
        <div className="container-fluid secondary-light-bg">
          <div className="row">
            <SectionText
              message={Texts.[language].[algae].algae_description}
              title={Texts.[language].[algae].algae_name[0]}
              title_highlight={Texts.[language].[algae].algae_name[1]}
              display='d-none'
            />
            <div className="col-12 col-md-6 px-0 order-2">
              <img src={require('../../media/images/'+props.data.raw_img).default} className="img-fluid"></img>
            </div>
          </div>
        </div>
      </section>
      <TechnicalSpecifications

        technical_specifications_title={Texts.[language].technical_specifications_title}

        product_label={Texts.[language].specifications_product_label}
        variety_label={Texts.[language].specifications_variety_label}
        style_label={Texts.[language].specifications_style_label}
        processing_label={Texts.[language].specifications_processing_label}
        drying_label={Texts.[language].specifications_drying_label}
        moisture_label={Texts.[language].specifications_moisture_label}
        impurities_label={Texts.[language].specifications_impurities_label}
        size_label={Texts.[language].specifications_size_label}
        place_label={Texts.[language].specifications_place_label}
        packing_label={Texts.[language].specifications_packing_label}
        certificates_label={Texts.[language].specifications_certificates_label}

        product={Texts.[language].[algae].specifications_product}
        variety={Texts.[language].[algae].specifications_variety}
        style={Texts.[language].[algae].specifications_style}
        processing={Texts.[language].[algae].specifications_processing}
        drying={Texts.[language].[algae].specifications_drying}
        moisture={Texts.[language].[algae].specifications_moisture}
        impurities={Texts.[language].[algae].specifications_impurities}
        size={Texts.[language].[algae].specifications_size}
        place={Texts.[language].[algae].specifications_place}
        packing={Texts.[language].[algae].specifications_packing}
        certificates={Texts.[language].[algae].specifications_certificates}
        comments={Texts.[language].[algae].specifications_comments}
      />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 px-0 pr-md-2 ">
              <ProductBanner
                img={props.data.algae_banners[0].algae_img}
                algae={Texts.[language].[algae].algae_banners[0].algae_name}
                description={Texts.[language].[algae].algae_banners[0].description}
                location={"/"+language+"/products/"+Texts.[language].[algae].algae_banners[0].algae_name.replace(" ", "_").toLowerCase()}
                btn_message={Texts.[language].btn_message}
                />
            </div>
            <div className="col-12 col-md-6 px-0 pl-md-2">
              <ProductBanner
              img={props.data.algae_banners[1].algae_img}
              algae={Texts.[language].[algae].algae_banners[1].algae_name}
              description={Texts.[language].[algae].algae_banners[1].description}
              location={"/"+language+"/products/"+Texts.[language].[algae].algae_banners[1].algae_name.replace(" ", "_").toLowerCase()}
              btn_message={Texts.[language].btn_message}
                />
            </div>
          </div>
        </div>
      </section>
      <Footer language={language}/>
      <FooterKoders language={language}/>
    </div>
  )
}

export default Products
